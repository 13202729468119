import Vue from 'vue'
import App from './App.vue'
import router from './router'

import { ApplicationInsights } from "@microsoft/applicationinsights-web";

Vue.config.productionTip = false;

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.VUE_APP_INSIGHTS,
  },
});
appInsights.loadAppInsights();
appInsights.trackPageView();

import Zoho from "@certree/zohochat";
Vue.use(Zoho);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
